<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)" enctype="multipart/form-data" id="fileForm">
        <b-form-input trim v-model="copyText" class="hidden" ref="copyInput"/>
        <b-row>
          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Upload File">
              <b-form-group label="Upload File" description="Allowed JPG, GIF, PNG or PDF">
                <b-form-file id="mediaFiles" name="mediaFiles" accept=".jpg, .png, .gif, .pdf" placeholder="Please select file..." drop-placeholder="You can drag and drop your documents here..." multiple @change="onSubmit"/>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-table
        ref="refListTable"
        primary-key="id"
        empty-text="No record found"
        class="position-relative"
        :items="fetchList"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        responsive striped bordered hover show-empty>

      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon :id="`row-${data.item.id}-copy`" icon="ClipboardIcon" size="16" @click="pathCopy(data.item)" class="mr-2"/>
          <b-tooltip title="Copy" class="cursor-pointer" :target="`row-${data.item.id}-copy`"/>
          <remove-button-table :data="data" :re-fetch-data="reFetchData"></remove-button-table>
        </div>
      </template>

      <template #cell(media_path)="data">
        <feather-icon icon="FileIcon" size="32" v-if="data.value.includes('.pdf')"/>
        <b-img :src="getApiFile(data.value)" fluid width="100" height="100" rounded v-if="!data.value.includes('.pdf')"/>
        <span v-if="data.value.includes('.pdf')"> {{ data.item.media_name }}</span>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <footer-table :data-meta="dataMeta"></footer-table>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number last-number>

            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>

            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>

          </b-pagination>
        </b-col>
      </b-row>
    </div>
    
    <Overlay :busy="busy"></Overlay>

  </b-card>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import Overlay from "@/components/Overlay.vue";
import router from '@/router'
import store from "@/store"
import storeModule from "@/views/cms/media/store"
import {email, required} from "@core/utils/validations/validations";
import {toastMessage} from "@core/utils/utils"
import {onUnmounted, ref} from '@vue/composition-api'
import {useToast} from "vue-toastification/composition";
import {BAvatar, BBadge, BButton, BCard, BCardText, BCol, BForm, BFormFile, BFormGroup, BFormInput, BFormInvalidFeedback, BImg, BMedia, BMediaAside, BMediaBody, BPagination, BRow, BTable, BTooltip} from "bootstrap-vue";
import {avatarText, getApiFile} from "@core/utils/filter";
import vSelect from 'vue-select'
import useList from './useList'
import {EditButtonTable, FooterTable, RemoveButtonTable} from "@/components/Table";
import ImagesButtonTable from "@/components/Table/ImagesButtonTable.vue";

export default {
  components: {
    FooterTable, BPagination,
    BFormInput,
    BTooltip,
    EditButtonTable, ImagesButtonTable, RemoveButtonTable,
    BAvatar,
    BBadge,
    BTable,
    BFormInvalidFeedback,
    BImg,
    BMediaAside,
    BCardText,
    BFormFile,
    BMediaBody,
    BMedia,
    BFormGroup,
    BCol,
    BRow,
    BCard,
    BForm,
    BButton,

    vSelect,
    Overlay,
    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
      }
    })

    const toast = useToast()
    const dataInfo = ref({
      contentId: router.currentRoute.params.id,
      languageId: 0,
      images: null
    })

    const copyText = ref('')
    const copyInput = ref(null);


    const {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,

      fetchList,
      reFetchData,
    } = useList()

    const onSubmit = () => {
      const formData = new FormData();

      const documents = document.getElementById('mediaFiles').files
      for (let i = 0; i < documents.length; i++) {
        let file = documents[i];
        formData.append('documents[' + i + ']', file);
      }

      console.log(formData)

      formData.append('media_path', '/');
      formData.append('media_type', '2');
      formData.append('media_name', '');

      busy.value = true
      store.dispatch('store/addItem', formData).then(response => {
        toastMessage(toast, 'success', response.data.message)
        reFetchData()
        document.getElementById("fileForm").reset();
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.error_message)
      }).finally(message => {
        busy.value = false
      })
    };

    const pathCopy = (data) => {
      copyText.value = getApiFile(data.media_path)
      copyInput.value.focus();
      document.execCommand('copy');
      navigator.clipboard.writeText(copyText.value);

      toastMessage(toast, 'success', 'File path copied')
    }

    return {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      dataInfo,
      copyText,
      copyInput,

      fetchList,
      reFetchData,
      pathCopy,
      onSubmit,
      getApiFile,
      avatarText,
    };
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
